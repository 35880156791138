<template lang="pug">
  b-container(fluid style="position:relative;")
    b-row.mb-3
      b-col
        h3.mb-3 Reportes

    b-form.mb-3
      b-row.mb-3
        b-col.mb-3(cols='6')
          b-form-group(label="Fecha de inicio")
            b-form-datepicker(placeholder="Selecciona fecha de inicio" locale="es" v-model="dateStart")
        b-col.mb-3(cols='6') 
          b-form-group(label="Fecha de fin")
            b-form-datepicker(placeholder="Selecciona fecha de fin" locale="es" v-model="dateEnd")
        b-col.mb-3(cols='6')
          b-form-group(label="Cliente")
            b-form-select(
              required,
              name="cliente",
              :options="parsedClientes",
              v-model="cliente_id",
              @change="casa_id = null; getCliente()"
            )
              template(#first)
                b-form-select-option(disabled, :value="null") -- Seleccionar cliente --
            b-link.text-sm.text-danger(@click="cliente_id=null;casa_id=null;casas=[];" v-if="cliente_id") Limpiar cliente
        b-col.mb-3(cols='6')
          b-form-group(label="Casa que visita")
            b-form-select(
              required,
              name="house",
              :options="casas",
              v-model="casa_id",
              :disabled="loadingCliente"
            )
              template(#first)
                b-form-select-option(disabled, :value="null") {{ loadingCliente ? 'Cargando casas...' : '-- Seleccionar casa --' }}
            b-link.text-sm.text-danger(@click="casa_id=null;" v-if="casa_id") Limpiar casa
      b-row.align-items-center
        b-col
          p.text-secondary Si no seleccionas Cliente ni Casa, mostrará información de todos los Clientes y Casas. Si solo seleccionas Cliente, mostrará información de todas las Casas de éste.
        b-col(cols='auto')
          .text-right
            b-dropdown(variant="success" no-caret :disabled='loading')
              template(#button-content)
                span Exportar reporte
                b-spinner.ml-2(small, v-if='loading')
                chevron-right-icon.icon-sm.ml-2(v-else)
              b-dropdown-item(@click="createReport('pdf')") PDF
              b-dropdown-item(@click="createReport('csv')") CSV
              b-dropdown-item(@click="createReport('json')") JSON

    b-table(:items="reportes", :fields="fields", responsive, no-border-collapse, show-empty)
      template(#empty)
        .text-center No hay reportes generados
      template(#cell(createdAt)="{item}")
        span {{ $moment(item.createdAt).format("DD MMMM YYYY, HH:mmA") }}
      template(#cell(done)="{item}")
        x-circle-icon.icon-md.text-danger(v-if="item.error")
        check-circle-icon.text-success.icon-md(v-else-if="item.done")
        span.text-warning(v-else) Generando...
      template(#cell(url)="{item}")
        span.text-secondary(v-if='item.error') Ocurrió un error generando el reporte.
        b-link(v-else @click="downloadReport(item.url)") {{ item.url }}
</template>

<script>
import Vue from 'vue'
import { createNamespacedHelpers, mapActions } from "vuex";
const clienteController = createNamespacedHelpers("cliente");
const reporteController = createNamespacedHelpers("reporte");
import Download from 'downloadjs'

export default {
  data () {
    return {
      fields: [
        {
          key: 'createdAt',
          label: 'Creado'
        },
        {
          key: 'done',
          label: 'Generado'
        },
        {
          key: 'url',
          label: 'URL'
        }
      ],
      loadingCliente: false,
      cliente_id: null,
      casa_id: null,
      casas: [],
      dateStart: null,
      dateEnd: null,
      timeout: null,
      shouldRefresh: true,
      loading: false
    }
  },
  computed: {
    ...clienteController.mapState({
      clientes: (state) => state.clientes,
    }),
    ...reporteController.mapState({
      reportes: (state) => state.reportes
    }),
    parsedClientes() {
      return Array.isArray(this.clientes)
        ? this.clientes.map((c) => {
            return {
              value: c.cliente_id,
              text: c.name,
            };
          })
        : [];
    },
  },
  methods: {
    ...mapActions([
      "cliente/all",
      "cliente/get",
      "reporte/all"
    ]),
    getClientes() {
      this.loadingData = true;
      this["cliente/all"]({
        params: {
          page: 1,
          limit: 500,
        },
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            // do something
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los clientes. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    getCliente() {
      this.loadingData = true;
      this.loadingCliente = true;
      this["cliente/get"]({
        cliente_id: this.cliente_id,
        callback: (res) => {
          this.loadingData = false;
          this.loadingCliente = false;

          if (res.success) {
            this.clienteName = res.resource.name;
            if (
              res.resource &&
              res.resource.Casas &&
              Array.isArray(res.resource.Casas)
            ) {
              this.casas = res.resource.Casas.map((c) => {
                return {
                  value: c.casa_id,
                  text: c.name,
                };
              });
            }
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo el cliente. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
    downloadReport (url) {
      // let url = new URL(`${process.env.VUE_APP_API}/api/v1/reporte`)
      // const initDate = this.$moment(this.dateStart, 'YYYY-MM-DD').startOf('day').toISOString()
      // const finalDate = this.$moment(this.dateEnd, 'YYYY-MM-DD').endOf('day').toISOString()
      // console.log(initDate, finalDate);
      // url.searchParams.set('init_date', initDate)
      // url.searchParams.set('final_date', finalDate)
      // if (this.cliente_id) {
      //   url.searchParams.set('cliente_id', this.cliente_id)
      // }
      // if (this.casa_id) {
      //   url.searchParams.set('casa_id', this.casa_id)
      // }
      // url.searchParams.set('format', format || 'pdf')
      window.open(url, '_blank')
    },
    getReportes() {
      clearTimeout(this.timeout)
      this.loadingData = true;
      this["reporte/all"]({
        params: {
          page: 1,
          limit: 100,
        },
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            // do something
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los reportes. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }

          if (this.shouldRefresh) {
            this.timeout = setTimeout(() => {
              this.getReportes()
            }, 3000);
          }
        },
      });
    },
    async createReport (format) {
      this.loading = true
      let url = new URL(`${process.env.VUE_APP_API}/api/v1/reporte`)
      const initDate = this.$moment(this.dateStart, 'YYYY-MM-DD').startOf('day').toISOString()
      const finalDate = this.$moment(this.dateEnd, 'YYYY-MM-DD').endOf('day').toISOString()
      console.log(initDate, finalDate);
      url.searchParams.set('init_date', initDate)
      url.searchParams.set('final_date', finalDate)
      if (this.cliente_id) {
        url.searchParams.set('cliente_id', this.cliente_id)
      }
      if (this.casa_id) {
        url.searchParams.set('casa_id', this.casa_id)
      }
      url.searchParams.set('format', format || 'pdf')
      url.searchParams.set('timestamp', new Date().getTime())
      const res = await Vue.request.get(url.href)
      if (res.status == 200) {
        this.loading = false

        if (format === 'pdf') {
          this.$bvToast.toast(
            res.data + '. Consulta el estado en la lista.',
            {
              title: "Generando reporte.",
              variant: "success",
            }
          );
        } else {
          this.$bvToast.toast(
            'Iniciando descarga...',
            {
              title: "Reporte generado",
              variant: "success",
            }
          );
          const mime = format === 'csv' ? 'text/csv' : 'application/json'
          Download(res.data, 'reporte.' + format, mime)
        }
      }
    }
  },
  mounted () {
    this.dateStart = this.$moment().startOf('month').format('YYYY-MM-DD')
    this.dateEnd = this.$moment().endOf('day').format('YYYY-MM-DD')
    this.getClientes()
    this.getReportes()
  },
  beforeDestroy () {
    this.shouldRefresh = false
    clearTimeout(this.timeout)
  }
}
</script>